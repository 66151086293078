
:root {
	--d: 1000ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1);
}

$icosize: calc(min(20vh, 20vw, 150px) * 0.4);
$menuWidht: max(50vw, 360px); 
$menuBG: rgba($color: #040b14, $alpha: 1);
$menuFG:rgba($color: #a8a9b4, $alpha: 1);
$menuFC:rgba($color: #149ddd, $alpha: 1);
 
 .menu-base { 
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    clear: both;
    z-index: 10;
    a{
      color: $menuFG;
      text-decoration: none;
    }
 }

 .menu {
    position: relative; 
    z-index: 10;  
 }

 .menu-icon {
    position: relative; 
    text-align: center;
    vertical-align: middle;
    animation: colorRotate 8s linear infinite;
    color: yellow;
    width: calc($icosize * 1.5);
    height: calc($icosize * 1.5);
 
    .main-icon {
       position: absolute;
       border-radius: 50%;
       border: 2px solid;
       width: $icosize;
       height: $icosize;
       line-height: calc($icosize - 5px);
       font-size: calc($icosize / 2);
       left: 10px;
       top: 10px;

       span {
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: inherit;
          animation: pulseAnimate 4s ease-out infinite;
          animation-delay: calc(.5s * var(--i));
          opacity: .7;
          color: rgba($color: #000000, $alpha: 0);
          border: 2px solid yellow;
       }
    }
 }

 .menu-content {
    position: relative; 
    z-index: 10;  
    width: 0px;
    transition: all .5s;
    -webkit-clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%,0% 0%);
    clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%,0% 0%);
 }

 .menu-border { 
    float: right;
    height: 0;
    width: 0;
    -webkit-clip-path: polygon(60% 0%, 100% 100%, 100% 0%);
    clip-path:  polygon(60% 0%, 100% 100%, 100% 0%);
    -webkit-shape-outside: polygon(60% 0%, 100% 100%, 100% 0%);
    shape-outside:  polygon(60% 0%, 100% 100%, 100% 0%);
    transition: all .5s; 
 }
.menu-item{   
   transform-origin: left; 
   transform: scaleX(0); 
   border-bottom: 50% solid black;
   color: $menuFG;
   text-align: right;
   background-color: $menuBG; 
   transition: transform .2s;
   transition-delay: calc(.1s * var(--i));
}
.menu-bottom{
   height: 100vh;
   text-align: left;
}
.mini-profle{
   img{
      margin: 10px; 
      left: 0;
      width: 110px;
      height: 110px;
      object-fit: cover;  
      outline: 5px solid $menuFG;  
      border-radius: 50%;   
      opacity: 0;
      transition: all 1s;
      transform-origin: left;
   }
}
.menu-overlay{  
   display: block;
   position: absolute; 
   box-sizing: content-box;
   transition: opacity 1s;
   background-color: black;
   opacity: 0;
   height: 0;
   width: 0; 
}
.menu-show {
    z-index: 1;
      .menu-overlay{  
         opacity: .5; 
         height: 100vh;
         width: 100vw; 
      }
    .menu-border {
       float: right;
       height: 100vh;
       width: $menuWidht;  
    }

    .menu-item::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      border-bottom: 2px dashed $menuFG; /* Adjust the style of the dashed line */
      opacity: .5;
      z-index: -1; /* Place the dashed line behind the text and icon */
    }
    .menu-item {  
      padding-right: 10px;  
       font-size: x-large; 
       padding: 10px;  
       transform: scaleX(1);
       i { 
          position: relative; 
          padding: 10px;
          background-color: $menuBG; 
          border-radius: 50%;


          i {
             opacity: 0;
             padding: 10px;
             color: $menuFC;
             position: absolute;
             right: 0;
             top: 0;
          }
       }
       a > span{
         display: inline-block;
         vertical-align: middle;
         background-color:  $menuBG;
         font-size: .5em;  
         width: 0;
         height: 0;
         transform: scale(0,0);
         transform-origin: left;
         overflow: hidden; 
         transition: all .5s ;
       } 
       a:hover > span{ 
         color: $menuFC;
         padding-left: 2px;
         padding-right: 2px;
         width: initial;
         height: initial;
         transform: scale(1,1);
       }
       > span{ 
         background-color: $menuBG; 
         position: absolute;
         left: 0;
         padding-left: 10px; 
         padding-right: 10px;
       }
    }

    .menu-item:hover {  
      transition-delay: 0;
      border: 2px solid; 
      border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 
      animation: borderRotate var(--d) linear infinite forwards;
       i {
          i {
             opacity: 1;
             animation: pulseAnimate .8s ease-out infinite, colorRotate .4s linear infinite;
             &:hover{
               animation: initial-state 0s;
             }
          }
       }
       .mini-profle{
         border: 0;
       }
    }
    .mini-profle{  
      text-align: left;
      vertical-align: middle;
      position: relative; 
      padding: 10px;
      background-color: $menuBG;  
      transition: all .3s;
      img{   
         opacity: 1;
      }

    }
    .menu-bottom{
      padding-top: 20px;
      text-align: left;
      bottom: 0;
    }
    .menu-content {
      width: $menuWidht;
      height: 100vh;
    }

    .menu-icon {
       display: none;
    } 
 }

 @keyframes pulseAnimate {
    100% {
       opacity: 0;
       transform: scale(1.5);
    }
 }

 @keyframes colorRotate {
    100% {
       filter: hue-rotate(360deg);
    }
 }

 
 @keyframes scaleXExpand {
   0%{
      
   transform: scaleX(0)
   }
   100% {
      
   transform: scaleX(1);
   }
}


@property --angle {
   syntax: '<angle>';
   initial-value: 90deg;
   inherits: true;
 }
 
 @property --gradX {
   syntax: '<percentage>';
   initial-value: 50%;
   inherits: true;
 }
 
 @property --gradY {
   syntax: '<percentage>';
   initial-value: 0%;
   inherits: true;
 }

 @keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes roundBorderRot {
   0%{ 
      border-top: 0;
   }
   
   25%{ 
      border-right: 0;
   }
   50%{ 
      border-bottom: 0;
   }
   75%{ 
      border-left: 0;
   }
   100%{ 

   }
}
 

.conic-anim-border{ 
   border: 2px solid; 
   border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 

}