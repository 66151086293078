/*General styles*/
$FGcolor:rgba($color: #a8a9b4, $alpha: 1);
$FGcolorDarker:rgba($color:#62647a, $alpha: 1);
$FGColor2:rgba($color:#9ed5ff,$alpha:1);
$FGColor3:rgba($color:#3370ff, $alpha:1);
$FCcolor:rgba($color: #149ddd, $alpha: 1);
$mainBg: rgba($color: #040b14, $alpha: 1);
$BG2:rgba($color: #112e55, $alpha: 1);
$BG3:rgba($color:#091a2f, $alpha:1);
$BG3Fade:rgba($color:#091a2f, $alpha:.5);
$Lighter:rgba($color:#fff, $alpha:.15);



:root {
  --glow-color: hsl(186 100% 69%);
}



body{
    background-color: $mainBg;
    color: white;
    overflow-x: hidden; 
}
.contents{
  margin: auto;
  width: 80vw;
}
.contents > div{
  
  padding: 2em;
  background-color: $Lighter;
  border-radius: 1vmax;
  margin: 1vmax;
}
.clickable{
    cursor: pointer;
}
.justify{
  
  text-align: justify;
  text-justify: inter-word;
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  position: sticky;
  top: 0;
    min-height:  min(200px,20vh);  
    background-size: cover;
    background-position: center right;
    transition: all .5s;
    z-index: 3; 
    width: 100%; 
    height: 100vh;
    background-repeat: no-repeat; 
    margin-bottom: 100px;
  }


  
  #hero:before {
    content: "";
    background: rgba(5, 13, 24, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  
  
  }
  
  #hero .hero-container {
    position: relative;
    z-index: 2; 
    height: 50%;
    width: 90%; 
  }
  
  #hero h1 { 
    transition: all .5s;
    font-size: 5vmax; 
    color: #fff; 
  } 
  #hero div{
    font-size: 3vmax;  
  }
  
 
.header-full{
  height: 100vh !important;
  background-position: right center !important;
}
.header-min{
  height: 0px !important;
  background-position: left center !important;  
  .hero-container { 
    height: calc(100% -  calc(min(20vh, 20vw, 150px) * 0.5)) !important;    
  }
  h1 { 
    font-size: 4vmin !important;       
  } 
  div{
    font-size: 2vmin !important;     
  }
}

/*--------------------------------------------------------------
# Hero Section END
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Text morph
--------------------------------------------------------------*/

$speed: 10s;
$wordCount: 2;

.morphing {
  color: rgba($color: #040b14, $alpha: 1);
  text-shadow:
    .1vmin .1vmin .1vmin #fff,
    -.1vmin .1vmin .1vmin #fff,
    -.1vmin -.1vmin 0 #fff,
    .1vmin -.1vmin 0 #fff;
  font-weight: bold;  
  width: max(360px, 70vw); 
  position: relative;  
}

.word {
  position: absolute;
  top: 0;   
  animation: word $speed infinite ease-in-out;

  @for $i from 0 to $wordCount {
    &:nth-child(#{$i + 1}) {
      animation-delay: ($speed / ($wordCount + 1) * $i) - $speed;
    }
  }

  @keyframes word {
    0%, 15%, 90% { filter: blur(0px); opacity: 1; }        
    30%, 45% { filter: blur(1em); opacity: 0; }        
  }
}




@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}
.conic-anim-border{ 
  border: 2px solid; 
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 
  animation: borderRotate 1s linear infinite forwards;
}

.sect-title{
  color: $FGColor2; 

  position: relative;  
  margin-top: 2vmax;
  margin-bottom: 2vmax;
  h2{
    display: inline-block;  
    width: 100%;
    padding-bottom: .2em;
    border-bottom: 2px dashed $FGColor2; /* Adjust the style of the dashed line */
    p{  
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
    }
    div{
      font-size: 30px;
      position: absolute; 
      
      right: 0;
      bottom: -5px;
      i{
        border-radius: 50%;
        background-color: $BG3;
      }
    }
  }
}
 


//////////////////
/// about
/// ////////////////
#about{
  margin: auto; 
  width: 80vw; 
}
#about > div{
  padding: 2em;
  background-color: $Lighter;
  border-radius: 1vmax;
  margin: 1vmax;
}

#profile2{    
  float: left;
  border-radius: 20%; 
  width: 30vmin;
  height: 30vmin;
  margin: 10px;
  margin-right: 5vw;
}

//
// Timline
//
ul.timeline {
  margin-top: 60px;
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' '; 
  display: inline-block;
  position: absolute; 
  width: 2px;
  height: calc(100% + 70px); 
  top: -50px;
  border-left: solid 1px;
  border-right: solid 1px;
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 
  animation: borderRotate 5s linear infinite forwards; 
}
ul.timeline > li { 
  border: 2px solid; 
  border-right: 0;
  border-bottom: 0;
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 
  animation: borderRotate 1s linear infinite forwards; 
  padding: 5px;
  padding-right: .5em;
  padding-bottom: 1em;
  transform-origin: top left;
  transition: all .5s;
  .desc{
    text-align: justify;
    text-justify: inter-word;
    margin-left: 2vmin;
    padding-bottom: 1em;
    margin-bottom: .5em;
    font-size: 1em;
  }
  .skills{
    margin-top: 5px;
    overflow-y: hidden;
    white-space: nowrap; 
    
  }
  .links{
    overflow-y: hidden;
    white-space: nowrap;
  }
  .gallery{
    margin-left: 2vmin;
    width: 100%; 
    overflow-y: hidden;
    white-space: nowrap;
    img{
      cursor: pointer;
      height: 5em;  
      margin: 0px .5vmin; 
      transition: all .5s;
      margin-bottom: 1em;
    }
    img:hover{
      opacity: .5;
    } 
  }

  .gallery::-webkit-scrollbar {
    box-shadow: inset 0 0 5px grey;  
    height: 5px;
  }
  
  .gallery::-webkit-scrollbar-thumb { 
    background: $FGcolor; 
    border-radius: 10px;
  }
  
  .gallery::-webkit-scrollbar-thumb:hover {
  background: $FCcolor; 
}
  .gallery::-webkit-scrollbar-track {
    background-color: $mainBg;
  }
} 

.timeline .timerange{
  width: 100%;
  height: 2em;
  position: relative;
  margin-bottom: 1em; 
  
  p{ 
    left: 0px;
    top: -25px;
    position: absolute;
    color: white;
    background-color: $BG2;  
    font-weight: bold;
    height: 40px;
    line-height:  40px;
    padding-left: 30px;
    padding-right: 25px; 
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%); 
    border-top: solid 2px;
    border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 
    animation: borderRotate 1s linear infinite forwards; 
  }
}
.timerange > i{ 
  font-size: 40px; 
  left: -25px;
  top: -25px;
  position: relative;
  background-color: $BG2;
  border-radius: 50%;
  z-index: 1;
  color: $FCcolor;
  i{
    color: white;
    position: absolute;
    left: 0;
    top: 0;
  }
} 
.rotateCircle{
  animation: rotateCircle 5s infinite forwards;
}
.glowIcon{
  position: relative;
  i{ 
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;
    animation: pulseAnimate .8s ease-out infinite, colorRotate .4s linear infinite;
  }
}
@keyframes pulseAnimate {
  100% {
     opacity: 0;
     transform: scale(1.5);
  }
}

@keyframes colorRotate {
  100% {
     filter: hue-rotate(360deg);
  }
}

@keyframes rotateCircle{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 700px) {
  #about{
    margin: auto; 
    width: 98vw; 
  }
  .contents{
    margin: auto;
    width: 98vw;
  }
  .contents > div{
    
    padding: 1em;
    background-color: $Lighter;
    border-radius: 1vmax;
    margin: 1vmin;
  }
}


.linkbtn{ 
  position: relative;   
  top: .3em;
  .lbcontainer{
    display: inline-block; 
  }
.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em .5em;
  
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em; 
  letter-spacing: 2px;
  margin-bottom: 10px;
  margin-left: .3em;
  margin-right: .3em;
  transition: all .2s;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left; 
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
} 

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
  margin-left: .5em;
  margin-right: .5em;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
} 

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}

}

.linkbtn2{
  
button {
  position: relative;
  background: #444;
  color: #fff;
  text-decoration: none; 
  border: none;
  letter-spacing: 0.1rem;
  font-size: 1em;
  padding: .5em 1em;
  transition: 0.2s;
}

button:hover {
  letter-spacing: 0.2rem;
  padding: .5em 1em; 
  margin-left: .5em;
  margin-right: .5em;
  background: var(--clr);
  color: var(--clr);
  /* box-shadow: 0 0 35px var(--clr); */
  animation: box 3s infinite;
}

button::before {
  content: "";
  position: absolute;
  inset: 2px;
  background: #272822;
}

button span {
  position: relative;
  z-index: 1;
}
button i {
  position: absolute;
  inset: 0;
  display: block;
}

button i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 80%;
  top: -2px;
  border: 2px solid var(--clr);
  background: #272822;
  transition: 0.2s;
}

button:hover i::before {
  width: 15px;
  left: 20%;
  animation: move 3s infinite;
}

button i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 20%;
  bottom: -2px;
  border: 2px solid var(--clr);
  background: #272822;
  transition: 0.2s;
}

button:hover i::after {
  width: 15px;
  left: 80%;
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes box {
  0% {
    box-shadow: #27272c;
  }
  50% {
    box-shadow: 0 0 25px var(--clr);
  }
  100% {
    box-shadow: #27272c;
  }
}

}


.toggleBtn{
  display: inline;
  button {
    border-radius: 10px;
    position: relative;
    background: #444;
    color: #fff;
    text-decoration: none; 
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1em;
    padding: .5em 1em;
    transition: 0.2s;
  }
  
  
  button i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #272822;
  }
  
  button span {
    position: relative;
    z-index: 1;
  }
  button i {
    position: absolute;
    inset: 0;
    display: block;
  }
  
  button i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes box {
    0% {
      box-shadow: #27272c;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #27272c;
    }
  }
  
  }

.toggleBtn[data-enable="true"]{
    
  button {
    letter-spacing: 0.2rem;
    padding: .5em 1em; 
    margin-left: .5em;
    margin-right: .5em;
    background: var(--clr);
    color: var(--clr);
    box-shadow: 0 0 25px var(--clr);
    border-radius: 0;
  }
  
  
  button i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  button i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }
  
}

.sortBtn{
  display: inline;
  button { 
    position: relative;  
    text-decoration: none; 
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1em;
    padding: .5em 1em;
    transition: 0.2s;
    background: var(--clr);
    color: var(--clr);
    box-shadow: 0 0 25px var(--clr); 
  }
  
  
  button >i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #272822;
  }
  
  button span {
    position: relative;
    z-index: 1;
  }
  button> i {
    position: absolute;
    inset: 0;
    display: block;
  }
  
  button >i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes box {
    0% {
      box-shadow: #27272c;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #27272c;
    }
  }
  
  }

.sortBtn[data-desc="yes"]{
     
  button> i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  button >i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }
  
}

.sub-header  {
  h1,h2,h3,h4{ 
    color:$FGColor2;
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1em;
    padding-bottom: 10px;
    position: relative;
  }
  h1:after,h2:after,h3:after,h4:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: $FCcolor;
  }
  h1:before,h2:before,h3:before,h4:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: $FGColor3;
  }
} 

.hide-project .evt-project,.hide-work .evt-work,.hide-achievement .evt-achievement,
 .hide-event .evt-event, .hide-education .evt-education{ 
    transform: scale(0);
    height: 0;
    padding: 0;
    margin: 0; 
} 

#top-bar{ 
  top: 0;
  position: sticky;
  width: calc(100% - 2rem);  
  z-index: 2;
  padding-bottom: 1px;
  padding-top: 28px;
  padding-left: 28px;
  margin-left: calc(2rem - 28px);
  overflow-y: hidden;
  white-space: nowrap;
} 

.img-modal{
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */ 
  padding-top: 1vh;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */ 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  text-align: center;
  vertical-align: middle;
  line-height: 100vh;
  .modal-content { 
    display: inline;
    width: 80%;
    max-width: 98vw;
    max-height: 98vh; 
    object-fit: contain;
    object-position: center;
  } 
  /* Add Animation - Zoom in the Modal */
.modal-content, .caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 0;
  right: 0; 
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  line-height: 40px;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
}



.skills::-webkit-scrollbar, .links::-webkit-scrollbar, #top-bar::-webkit-scrollbar {
  box-shadow: inset 0 0 5px grey;  
  height: 5px;
}

.skills::-webkit-scrollbar-thumb,.links::-webkit-scrollbar-thumb, #top-bar::-webkit-scrollbar-thumb { 
  background: $FGcolor; 
  border-radius: 10px;
}

.skills::-webkit-scrollbar-thumb:hover,.links::-webkit-scrollbar-thumb:hover, #top-bar::-webkit-scrollbar-thumb:hover {
background: $FCcolor; 
}
.skills::-webkit-scrollbar-track, .links::-webkit-scrollbar-track, #top-bar::-webkit-scrollbar-track {
  background-color: $mainBg;
}




//skill items
$border-start-color: #ffff; // Start color of the gradient
$border-end-color: #00ff00; // End color of the gradient
 
.skill-item{
  position: relative; 
  margin: .5em; 
  .text{ 
    .name{
      $gradient-scale: 30%; // Default gradient scale
      font-size: 1.3em;
      font-style: bold;     
      &:before {
        content : "";
        position: absolute;
        left    : 0; 
        height  : 1px;
        width   : calc(30% + var(--p)) ;  /* percentage of the element width*/ 
        border-bottom:2px dashed $FCcolor; 
        margin-top:1.5em; /*move the border below the text*/ 
      }
    }
    .count{
      padding-top: .3em;

      color: $FGColor2;
    }
  }
  .projects{ 
    transform-origin: left;
    transform: scaleX(0); 
    height: 0;
    transition: all .3s;  
  }
}
.skill-item:hover,.skill-item:active{
  
  border: 2px solid; 
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30; 
  animation: borderRotate 1s linear infinite forwards;
  .projects{ 
    transform-origin: left; 
    transform: scaleX(1);
    transform: scaleY(1);
    height: fit-content;
    transition: all .3s;  
  }
}




 